.filterPopover {
  .divider {
    margin: 0 0 4px;
  }

  .searchBox {
    margin: 4px 0 8px;
  }

  .optionsBtn {
    width: 100%;
    justify-content: start;

    .optionsBtnArrow {
      margin: auto;
    }
  }
}