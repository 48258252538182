@import '../../../assets/scss/variables.module';

.modalCard {

  :global(.ant-card-body) {
    padding: 0
  }
  
  .btnCol {
    text-align: center;
    padding: 0 8px;

    .btnFechar {
      width: 100%;

      background-color: $red-1;
      color: $red-6;

      &:hover {
        background-color: $red-2;
      }
    }

    .btnHistorico {
      width: 100%;
      gap: 4px;
    
      background-color: $primary-1;
      color: $primary-6;
    
      &:hover {
        background-color: $primary-6 !important;
      }
    }
  }
  
  .divider {
    margin: 0 0 12px;
  }
  
  .form {
    
    .dragger {
      
      :global(.ant-upload-drag) {
        background-color: inherit;
        border: 0;
      }
    }
  }
}